@import '@kanety/stimulus-tree';

.list-group {
  &.list-group-root {
    padding: 0;
    overflow: hidden;

    > .list-group-item:first-child {
      border-top-width: 0;
    }

    > .list-group {
      > .list-group-item {
        padding-left: 30px;
      }

      > .list-group {
        > .list-group-item {
          padding-left: 45px;
        }
      }
    }

    .list-group {
      margin-bottom: 0;
    }

    .list-group-item {
      border-radius: 0;
      border-width: 1px 0 0 0;
    }
  }
}
