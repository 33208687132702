@mixin common-font-style {
    font-size: 1.1rem !important;
    font-weight: 500 !important;
    line-height: 1.5 !important;
}

.ts-wrapper {
    &.form-control,
    &.form-select {
        display: block !important;
        width: 100% !important;
        padding: 0.475rem 0.3rem !important;
        @include common-font-style;
        background-clip: padding-box !important;
        appearance: none !important;
        border-radius: 0.475rem !important;
    }
}

.ts-dropdown,
.ts-control,
.ts-control input {
    @include common-font-style;
}

.focus .ts-control {
    border: none !important;
    box-shadow: none !important;
}
